import React from 'react';
import Layout from '../../../../components/layout';

/*global Stripe*/
// const stripe = Stripe('pk_test_9Bt4TS5Q8QwdEf1Wt4S3OfHp')
const launchCheckout = (sku, level) => {
  // stripe.redirectToCheckout({
  //   items: [{
  //     sku: sku,
  //     quantity: 1
  //   }],
  //   successUrl: 'http://localhost:8000/courses/angular/custom-components/',
  //   cancelUrl: 'http://localhost:8000/courses/angular/custom-components/'
  // })
};

const AngularCustomComponents = () => (
  <Layout>
    <article>
      <div className="hero is-danger is-medium is-bold">
        <div className="hero-body">
          <h1 className="title is-1">Custom Comoponents in Angular</h1>
        </div>
      </div>
      <div className="container">
        <div className="content">
          <h2 className="title is-5">Your Custom Component</h2>
          <p>
            This is a paragraph of content yo. You know we make the best content.
            Rick and Morty for life!
          </p>
          <pre>
            <code>This is code brah.</code>
          </pre>
        </div>
        <div className="pricing-table">
          <div className="pricing-plan">
            <div className="plan-header">Basic</div>
            <div className="plan-price">
              <span className="plan-price-amount">Free</span>
            </div>
            <div className="plan-items">
              <div className="plan-item">Written Course</div>
              <div className="plan-item">Access to code examples</div>
              <div className="plan-item">-</div>
              <div className="plan-item">-</div>
              <div className="plan-item">-</div>
            </div>
            <div className="plan-footer">
              <button className="button is-fullwidth" disabled="disabled">
                Current plan
              </button>
            </div>
          </div>

          <div className="pricing-plan is-warning">
            <div className="plan-header">Rad</div>
            <div className="plan-price">
              <span className="plan-price-amount">
                <span className="plan-price-currency">$</span>39
              </span>
            </div>
            <div className="plan-items">
              <div className="plan-item">Written Course</div>
              <div className="plan-item">Access to code examples</div>
              <div className="plan-item">Access to Slack Chat</div>
              <div className="plan-item">-</div>
              <div className="plan-item">-</div>
            </div>
            <div className="plan-footer">
              <button
                className="button is-fullwidth"
                onClick={() => launchCheckout('sku_GAUlJTV0qMfrtX')}
              >
                Choose
              </button>
            </div>
          </div>

          <div className="pricing-plan is-active">
            <div className="plan-header">Mega</div>
            <div className="plan-price">
              <span className="plan-price-amount">
                <span className="plan-price-currency">$</span>60
              </span>
            </div>
            <div className="plan-items">
              <div className="plan-item">Written Course</div>
              <div className="plan-item">Access to code examples</div>
              <div className="plan-item">Access to Slack Chat</div>
              <div className="plan-item">Full Video Courses</div>
              <div className="plan-item">-</div>
            </div>
            <div className="plan-footer">
              <button
                className="button is-fullwidth"
                onClick={() => launchCheckout('sku_GAUmiGFDlNsye4')}
              >
                Choose
              </button>
            </div>
          </div>

          <div className="pricing-plan is-danger">
            <div className="plan-header">Ultimate</div>
            <div className="plan-price">
              <span className="plan-price-amount">
                <span className="plan-price-currency">$</span>100
              </span>
            </div>
            <div className="plan-items">
              <div className="plan-item">Written Course</div>
              <div className="plan-item">Access to code examples</div>
              <div className="plan-item">Access to Slack Chat</div>
              <div className="plan-item">Video Course</div>
              <div className="plan-item">5 hrs 1-on-1 Instruction</div>
            </div>
            <div className="plan-footer">
              <button
                className="button is-fullwidth"
                onClick={() => launchCheckout('sku_GAUmZ5ltKwUGMm')}
              >
                Choose
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Layout>
);

export default AngularCustomComponents;
